
.search-form-container {
    backdrop-filter: contrast(0.5) brightness(1.5) blur(8px);
    box-shadow: inset 0px 0px 1px 1px rgba(255, 255, 255, 0.4);
}

.tagline {
    text-shadow: 0px 2px 6px rgba(0,0,0,0.5);

}
