@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// body {
//     margin: 0;
//     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }
// code {
//     font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
// }

.height-limited {
    height: calc(100vh - 140px);
    max-height: calc(100vh - 140px);
}

span.cap {
    display: inline-block;
}

.cap:first-letter {
    text-transform: uppercase;
}