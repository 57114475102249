.PropertyEntry {
    transition: scale 0.2s ease-in-out;

    .RefTag {
        backdrop-filter: contrast(0.7) brightness(1.4) blur(6px);
    }

    &:hover {
        scale: 1.02;
    }

    .PropertyEntryInner {
        transition: box-shadow 0.2s ease-in-out;

    }
}
