.swiper-slide {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    
    img {
        width: 100%;
        height: 100%;
    }
}

.swiper-button-next,
.swiper-button-prev {
    color: white;
    text-shadow: 0px 1px 5px black;
    opacity: 0.4;

    &:hover {
        opacity: 1;
    }
}

.swiper-pagination-bullet {
    background-color: black;
    opacity: 0.6;

    &.swiper-pagination-bullet-active {
        background-color: white;
        box-shadow: 0px 1px 5px black;
        opacity: 0.8;

        &:hover {
            opacity: 1;
        }
    }

    &:hover {
        background-color: white;
    }
}
