.Map {
    .cluster {
        img {
            filter: brightness(180%) blur(1px);
            opacity: 0.6;
            scale: 0.5;
        }

        div {
            color: #fff !important;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
        }

        &.cluster-home-n {
            img {
                filter: none;
                opacity: 1;
                scale: 1;
            }
    
            div {
                color: #000 !important;
                text-shadow: none;
            }   
        }
    }
}